import styles from './Entities.module.sass';
import { Route } from './Route';
import { ReactElement, useState } from 'react';
import { List } from './f2/forms/List';
import { CreateForm } from './f2/forms/CreateForm';
import { ViewReviewForm as ViewReviewForm } from './f2/forms/ViewReviewForm';
import { CreateEntitiesForm } from './f2/forms/CreateEntitiesForm';
export const Entities = (): ReactElement => {
  const [hash, setHash] = useState(window.location.hash);
  window.addEventListener('hashchange', () => setHash(window.location.hash));
  if (/^#\/Entities\/?$/.test(window.location.hash))
    window.location.hash = '/Entities/shared-text';
  return (
    <div className={styles.Entities}>
      <div className={styles.routeList}>
        <Route
          match={/^#\/Entities\/(drafts|shared-text|service)\/?$/}
          hash={hash}
          from='left'
        >
          <List />
        </Route>
        <Route
          match={
            /^#\/?Entities\/(create|(drafts|shared-text|service)\/.+\/edit(-loc)?(\?context=.*)?)\/?$/
          }
          hash={hash}
        >
          <CreateForm />
        </Route>
        <Route
          match={/^#\/?Entities\/(drafts|shared-text|service)\/[^/]+\/?$/}
          hash={hash}
        >
          <ViewReviewForm />
        </Route>
        <Route
          match={
            /^#\/?Entities\/shared-text\/service\/[^/]+\/create-entities\/?$/
          }
          hash={hash}
        >
          <CreateEntitiesForm />
        </Route>
      </div>
    </div>
  );
};

export default Entities;
