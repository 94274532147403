import {
  ServiceEntity,
  SharedTextEntity,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';

export const fieldTitles: Partial<
  Record<keyof (ServiceEntity | SharedTextEntity), string>
> = {
  entityId: 'Entity ID',
  locale: 'Locale',
  value: 'Value',
};
