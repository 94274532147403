import {
  RegionalNameInformation,
  EntityInformation,
  ServiceEntity,
  SharedTextEntity,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { Link } from '@amzn/awsui-components-react';
import { ReactNode } from 'react';

// This is still bad and needs refactoring later, but it should fix the build for now
export const makeEntityOrDraftCell = (
  entity: EntityInformation,
  prop: keyof (ServiceEntity | SharedTextEntity)
): ReactNode => {
  if ('sharedText' in entity && entity.sharedText) {
    if (prop === 'entityId') {
      return (
        <Link
          href={`#/Entities/shared-text/${encodeURIComponent(
            entity.sharedText.entityId
          )}`}
        >
          {entity.sharedText.entityId}
        </Link>
      );
    }
    if (prop === 'locale') {
      return entity.sharedText.locale;
    }
  } else if ('service' in entity && entity.service) {
    if (prop === 'entityId') {
      return (
        <Link
          href={`#/Entities/service/${encodeURIComponent(
            entity.service.entityId
          )}`}
        >
          {entity.service.entityId}
        </Link>
      );
    }
    if (prop === 'locale') {
      return entity.service.locale;
    }
  }

  if (prop === 'value') {
    if ('sharedText' in entity && entity.sharedText) {
      const regionalValues = Object.values(entity.sharedText.value);
      return regionalValues
        .map((regionalValue) =>
          regionalValue?.value.map((locString) => locString?.value).join(', ')
        )
        .join('; ');
    } else if ('service' in entity && entity.service) {
      const regionalValues = Object.values(entity.service.value);
      return regionalValues
        .map((regionalValue) =>
          regionalValue?.value.map((locString) => locString?.value).join(', ')
        )
        .join('; ');
    }
  }

  return '';
};
