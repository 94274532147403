import styles from './List.module.sass';
import { Tabs } from '@amzn/awsui-components-react';
import { EntityList } from '../../EntityList';
import { EntityReviewList } from '../../EntityReviewList';
import { ReactElement, useEffect, useState } from 'react';

export const List = () => {
  // Get the current hash path
  const currentPath = location.hash.includes('shared-text')
    ? 'shared-text'
    : location.hash.includes('service')
    ? 'service'
    : '';

  const handleTabChange = (e: any) => {
    const isDrafts = e.detail.activeTabHref.includes('drafts');
    if (isDrafts) {
      location.hash = currentPath
        ? `#/Entities/${currentPath}/drafts`
        : '#/Entities/drafts';
    } else {
      location.hash = `#/Entities/${currentPath}`;
    }
  };

  const getActiveTabId = () => {
    if (location.hash.includes('/drafts')) {
      return 'drafts';
    }
    return 'entities';
  };

  return (
    <Tabs
      className={styles.List}
      variant='container'
      tabs={[
        {
          label: 'Entities',
          id: 'entities',
          content: <EntityList />,
          href: `#/Entities/${currentPath}`,
        },
        // TODO: Enable for Adding and Modifying Entities
        // {
        //   label: 'Entity Drafts',
        //   id: 'drafts',
        //   content: <EntityReviewList />,
        //   href: currentPath ? `#/Entities/${currentPath}/drafts` : '#/Entities/drafts',
        // },
      ]}
      activeTabId={getActiveTabId()}
      onChange={handleTabChange}
    />
  );
};
