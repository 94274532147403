import {
  ListOfferingsCommand,
  Offering,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { callApi } from '../../../client';
import { ReactElement } from 'react';

const OFFERING_MAP = 'offeringMap';
const OFFERING_MAP_LAST_MODIFIED = 'offeringMapLastModified';

const metricLog = (serviceId: String, found: boolean): void => {
  const timestamp = new Date(Date.now()).toUTCString();
  console.log(
    `METRIC: Timestamp - ${timestamp}, serviceId - ${serviceId}, found? - ${found}`
  );
};

function getMonikerServiceNameHelper(
  serviceId: string,
  offeringMap: Map<String, String>
): String | null {
  if (offeringMap && offeringMap.size > 0) {
    const offeringName = offeringMap.get(serviceId.toLowerCase()) || null;
    if (offeringName) {
      metricLog(serviceId, true);
      return offeringName;
    }
  }
  metricLog(serviceId, false);
  return null;
}

export function getMonikerServiceName(
  serviceId: string,
  token: string
): ReactElement {
  const callMonikerAPI = (token: string): Map<String, String> => {
    var tempMap = new Map<String, String>();
    if (!token) {
      console.warn('Token not present');
      return tempMap;
    }
    callApi(new ListOfferingsCommand({}), token)
      .then((res) => {
        res.offerings.forEach((offering: Offering) => {
          tempMap.set(offering.offeringId.toLowerCase(), offering.offeringName);
        });
        console.log(`Saving offeringMap to localStorage, size=${tempMap.size}`);
        localStorage.setItem(
          OFFERING_MAP,
          JSON.stringify(Object.fromEntries(tempMap))
        );
        localStorage.setItem(
          OFFERING_MAP_LAST_MODIFIED,
          new Date(Date.now()).toUTCString()
        );
      })
      .catch((err) => {
        console.error(`ERROR when calling Moniker listOfferings: ${err}`);
        return tempMap;
      });
    return tempMap;
  };

  // Using local storage & ListOfferings to avoid flooding Moniker with GET offering API calls.
  // Cache will be refreshed on table row load if data is >1hr old.
  var retrievedOfferingMap = new Map<String, String>(
    Object.entries(JSON.parse(localStorage.getItem(OFFERING_MAP) || '{}'))
  );
  if (retrievedOfferingMap.size <= 0) {
    retrievedOfferingMap = callMonikerAPI(token);
  }
  const offeringMapLastModified = localStorage.getItem(
    OFFERING_MAP_LAST_MODIFIED
  );
  if (offeringMapLastModified) {
    const offeringMapLastModifiedTime = new Date(offeringMapLastModified);
    const now = new Date();
    const diffInHours =
      Math.abs(now.getTime() - offeringMapLastModifiedTime.getTime()) / 36e5;
    if (diffInHours > 1) {
      console.log(
        `Moniker offeringMap is ${diffInHours} hours old, refreshing...`
      );
      retrievedOfferingMap = callMonikerAPI(token);
    }
  }
  const offeringName = getMonikerServiceNameHelper(
    serviceId,
    retrievedOfferingMap
  );
  return offeringName ? <>{offeringName}</> : <>{`[${serviceId}]`}</>;
}
