import {
  EntityInformation,
  GetEntityCommand,
  Locale,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import formStyles from '../Form.module.sass';
import {
  Box,
  Button,
  Container,
  Form,
  FormField,
  Input,
  Modal,
  SpaceBetween,
  RadioGroup,
  Table,
} from '@amzn/awsui-components-react';
import { ReactElement, useEffect, useState } from 'react';

import { callApi } from '../../../client';
import { useAuthState } from '../../../authentication';

export const ViewReviewForm = (): ReactElement => {
  const [notice, setNotice] = useState<{
    title: string;
    body: string;
    callback?: () => void;
  }>();
  const { token } = useAuthState();
  const [entity, setEntity] = useState<EntityInformation>();
  const [loading, setLoading] = useState(false);
  const [entityQueried, setEntityQueried] = useState(false);

  const serviceEntityId = /Entities\/service\/(.+?)\/?$/.exec(
    location.hash
  )?.[1];
  const sharedTextEntityId = /Entities\/shared-text\/(.+?)\/?$/.exec(
    location.hash
  )?.[1];

  const entityId =
    serviceEntityId || sharedTextEntityId
      ? decodeURIComponent(String(serviceEntityId || sharedTextEntityId))
      : undefined;
  const entityType = serviceEntityId ? 'SERVICE_ENTITY' : 'SHARED_TEXT_ENTITY';

  useEffect(
    () => setLoading(entityQueried && !entity),
    [entityQueried, entity]
  );

  useEffect(() => {
    if (entity || entityQueried || !entityId || !token) return;
    setEntityQueried(true);

    callApi(
      new GetEntityCommand({
        entityId,
        locale: Locale.ENGLISH,
      }),
      token
    )
      .then((res) => {
        setEntity(res.entity);
      })
      .catch((err) =>
        setNotice({
          title: 'GetEntity call failed',
          body: String(err),
        })
      );
  }, [entity, entityQueried, entityId]);

  return (
    <Container>
      <Form>
        <SpaceBetween size='l'>
          <SpaceBetween size='l' direction='horizontal'>
            <SpaceBetween
              direction='vertical'
              size='m'
              className={formStyles.column}
            >
              <FormField label='Entity ID'>
                <Input disabled={true} value={entityId ?? ''} />
              </FormField>
              <FormField label='Entity Type'>
                <Input disabled={true} value={entityType ?? ''} />
              </FormField>
              <FormField label='Locale'>
                <Input
                  disabled={true}
                  value={
                    entity
                      ? 'sharedText' in entity && entity.sharedText
                        ? entity.sharedText.locale
                        : 'service' in entity && entity.service
                        ? entity.service.locale
                        : ''
                      : ''
                  }
                />
              </FormField>
            </SpaceBetween>
            <SpaceBetween
              direction='vertical'
              size='m'
              className={formStyles.column}
            >
              <FormField label='Values'>
                <Table
                  columnDefinitions={[
                    { header: 'Region', cell: (item) => item.region },
                    { header: 'Value', cell: (item) => item.value },
                  ]}
                  items={entity ? getFormattedValues(entity) : []}
                />
              </FormField>
            </SpaceBetween>
          </SpaceBetween>
        </SpaceBetween>
      </Form>
      <Modal
        onDismiss={notice?.callback}
        header={<Box variant='h2'>{notice?.title}</Box>}
        visible={Boolean(notice)}
        size='small'
        footer={
          <SpaceBetween size='m' direction='horizontal' alignItems='end'>
            <Button variant='primary' onClick={notice?.callback}>
              OK
            </Button>
          </SpaceBetween>
        }
      >
        {notice?.body}
      </Modal>
    </Container>
  );
};

// Helper function to format values for the table
const getFormattedValues = (entity: EntityInformation) => {
  const entityData =
    'sharedText' in entity && entity.sharedText
      ? entity.sharedText
      : 'service' in entity && entity.service
      ? entity.service
      : null;

  if (!entityData) return [];

  return Object.entries(entityData.value).map(([region, regionalValue]) => ({
    region,
    value: regionalValue?.value.map((locString) => locString?.value).join(', '),
  }));
};
