import {
  ServiceEntity,
  SharedTextEntity,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
const hiddenFields = ['dataSource'] as const;
type HiddenField = typeof hiddenFields[number];
type OmitHidden<T> = Omit<T, HiddenField>;
export const entityPropList: (keyof (ServiceEntity | SharedTextEntity))[] = [
  'entityId',
  'locale',
  'value',
];

// TODO: enable for creation of entity
// export const entityReviewPropList: (keyof OmitHidden<SharedTextEntityReview>)[] = [
//   'offeringId',
//   'offeringName',
//   'prefixRequired',
//   'brandPrefix',
//   'regions',
//   'shortDescription',
//   'launchStatus',
//   'doNotUseName',
//   'deprecatedName',
//   'generalNotes',
//   'resourceExamples',
//   'docsCategories',
//   'marketingCategories',
//   'type',
//   'locGuidanceRequired',
//   'locSubscribersList',
//   'offeringReviewId',
//   'offeringId',
//   'offeringReviewStatus',
//   'extraLongName',
//   'longName',
//   'shortName',
//   'extraShortName',
//   'ripServiceId',
//   'localizationGuidance',
//   'localizationNotes',
//   'localizationWords',
//   'createdAt',
//   'modifiedAt',
//   'createdBy',
//   'reviewedBy',
//   'userPermissions',
//];
