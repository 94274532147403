import { useState, useEffect, ReactElement } from 'react';
import SideNavigation, {
  SideNavigationProps,
} from '@amzn/awsui-components-react/polaris/side-navigation';
import { useLocation } from 'react-router';
import { getSideNavActiveHref } from './utils';
import styles from './navigation.module.scss';
import { isProd } from '../../Pages/servicePackageInfo/utils';
import Item = SideNavigationProps.Item;

const items: Item[] = [
  {
    type: 'section',
    text: 'Build Information',
    items: [{ type: 'link', text: 'Service information', href: '#/services' }],
  },
];
if (!isProd())
  items.push({
    type: 'section',
    text: 'Offering Information',
    items: [{ type: 'link', text: 'Moniker', href: '#/Moniker' }],
  });
if (!isProd())
  items.push({
    type: 'section',
    text: 'Entity Information',
    items: [
      { type: 'link', text: 'Shared Text', href: '#/Entities/shared-text' },
      { type: 'link', text: 'Service', href: '#/Entities/service' },
    ],
  });
const SideNav = (): ReactElement => {
  const [activeHref, setActiveHref] = useState(window.location.hash);

  // Update the activeHref as the url changes
  const location = useLocation();
  useEffect(() => {
    setActiveHref(getSideNavActiveHref(location.pathname));
  }, [location.pathname]);

  return (
    <SideNavigation
      activeHref={activeHref}
      header={{ href: '#/', text: 'Virtual Smiley' }}
      className={activeHref === '#/' ? styles.homepageSideNav : ''}
      onFollow={(event) => {
        setActiveHref(event.detail.href);
      }}
      items={items}
    />
  );
};

export default SideNav;
