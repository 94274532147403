import {
  Header,
  Table,
  TableProps,
  TextFilter,
} from '@amzn/awsui-components-react/polaris';
import { makeEntityOrDraftCell } from './makeEntityOrDraftCell';
import { fieldTitles } from './f2/fieldTitles';
import { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Prefs,
  EntityListCollectionPreferences,
} from './EntityListCollectionPreferences';
import { entityPropList } from './RowPropTypes';
import { Pagination } from '@amzn/awsui-components-react';
import { useAuthState } from '../authentication';
import {
  ListEntitiesCommand,
  EntityInformation,
  EntityType,
  Locale,
  ServiceEntity,
  SharedTextEntity,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { callApi } from '../client';

import { ExportEntityList } from './ExportEntityList';

export const EntityList = (): ReactElement => {
  const { token } = useAuthState();
  const [sortingDescending, setSortingDescending] = useState(false);
  const location = useLocation();

  const getHeaderText = () => {
    if (location.pathname.includes('/shared-text')) {
      return 'Shared Text Entities';
    }
    return 'Service Entities';
  };

  const [sortColumn, setSortColumn] = useState<
    TableProps.SortingColumn<EntityInformation>
  >({
    sortingField: 'entityId',
  });
  const [rows, setRows] = useState<EntityInformation[]>([]);
  const [filteredRows, setFilteredRows] = useState<EntityInformation[]>([]);

  const orderRows = () => {
    return [...filteredRows].sort((a, b) => {
      const valueA =
        ('sharedText' in a ? a.sharedText?.entityId : a.service?.entityId) ||
        '';
      const valueB =
        ('sharedText' in b ? b.sharedText?.entityId : b.service?.entityId) ||
        '';

      return sortingDescending
        ? valueB.localeCompare(valueA)
        : valueA.localeCompare(valueB);
    });
  };

  const [orderedRows, setOrderedRows] = useState(orderRows());
  const [filteringText, setFilteringText] = useState('');

  const getPage = (page: number, size: number) =>
    orderedRows.slice((page - 1) * size, page * size);

  const COLUMNS_STORAGE_KEY = 'entityColumns';
  const PAGE_SIZE_STORAGE_KEY = 'entityPageSize';

  const VISIBLE_COLUMNS: (keyof (ServiceEntity | SharedTextEntity))[] = [
    'entityId',
    'locale',
    'value',
  ];

  const [preferences, setPreferences] = useState<Prefs>({
    pageSize: 20,
    wrapLines: true,
    visibleContent: VISIBLE_COLUMNS,
  });

  useEffect(() => {
    const savedPageSize = localStorage.getItem(PAGE_SIZE_STORAGE_KEY);
    if (savedPageSize) {
      const pageSize = parseInt(savedPageSize);
      setPreferences((prev) => ({ ...prev, pageSize }));
    }
  }, []);

  useEffect(() => {
    if (preferences.pageSize) {
      localStorage.setItem(
        PAGE_SIZE_STORAGE_KEY,
        preferences.pageSize.toString()
      );
    }
  }, [preferences.pageSize]);

  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    if (!token) return console.warn('Token not present');

    const entityType = location.pathname.includes('/shared-text')
      ? EntityType.SHARED_TEXT
      : EntityType.SERVICE_ENTITY;

    console.log('Fetching entities of type:', entityType);

    callApi(
      new ListEntitiesCommand({
        locale: Locale.ENGLISH,
        entityType: entityType,
      }),
      token
    )
      .then((res) => {
        console.log('Received entities:', res.entities);
        setRows(res.entities);
      })
      .catch((e) => {
        console.error(e);
        setRows([]);
      });
  }, [token, location.pathname]);

  useEffect(() => {
    setFilteredRows(
      rows.filter((entity: EntityInformation) => {
        const entityId =
          'sharedText' in entity && entity.sharedText
            ? entity.sharedText.entityId
            : 'service' in entity && entity.service
            ? entity.service.entityId
            : '';

        return entityId.toLowerCase().includes(filteringText.toLowerCase());
      })
    );
    setCurrentPage(1);
  }, [rows, filteringText, location.pathname]);

  const countPages = () =>
    Math.ceil(filteredRows.length / (preferences.pageSize ?? 20));

  const [totalPages, setTotalPages] = useState(countPages());

  const [items, setItems] = useState<EntityInformation[]>([]);

  useEffect(() => {
    setItems(getPage(currentPage, preferences.pageSize ?? 20));
  }, [preferences.pageSize, currentPage, orderedRows, totalPages]);

  useEffect(() => {
    setTotalPages(countPages());
  }, [preferences.pageSize, filteredRows]);

  useEffect(
    () => setOrderedRows(orderRows()),
    [filteredRows, sortingDescending, sortColumn]
  );

  return (
    <>
      <Table
        stickyColumns={{ first: 1, last: 0 }}
        variant='container'
        contentDensity='comfortable'
        filter={
          <TextFilter
            filteringPlaceholder='Find an entity'
            filteringText={filteringText}
            onChange={(e) => setFilteringText(e.detail.filteringText)}
          />
        }
        sortingColumn={sortColumn}
        onSortingChange={(e) => {
          setSortColumn(e.detail.sortingColumn);
          setSortingDescending(e.detail.isDescending ?? true);
        }}
        visibleColumns={preferences.visibleContent}
        header={
          <Header
            counter={`(${filteredRows.length})`}
            actions={<ExportEntityList rows={filteredRows} />}
          >
            {getHeaderText()}
          </Header>
        }
        sortingDescending={sortingDescending}
        columnDefinitions={[
          // {
          //   id: 'controls',
          //   header: '',
          //   isRowHeader: false,
          //   cell: (serv) => (
          //     <Center>
          //       <SpaceBetween
          //         size={'s'}
          //         direction={'horizontal'}
          //         alignItems={'center'}
          //       >
          //         <Button
          //           href={`/Moniker/offerings/${serv.offeringId}`}
          //           variant={'icon'}
          //           iconName='edit'
          //         />
          //         <Button
          //           // href={ `/Moniker/offerings/${serv.offeringId}`}
          //           variant={'icon'}
          //           iconName='remove'
          //         />
          //       </SpaceBetween>
          //     </Center>
          //   ),
          // },
          ...entityPropList.map(
            (prop): TableProps.ColumnDefinition<EntityInformation> => ({
              id: prop,
              header: fieldTitles[prop],
              sortingField: prop,
              isRowHeader: prop === 'entityId',
              cell: (entity: EntityInformation) =>
                makeEntityOrDraftCell(entity, prop),
            })
          ),
        ]}
        items={items}
        preferences={
          <EntityListCollectionPreferences
            fields={entityPropList}
            preferences={preferences}
            setPreferences={setPreferences}
          />
        }
        pagination={
          <Pagination
            onPreviousPageClick={() => setCurrentPage(currentPage - 1)}
            currentPageIndex={currentPage}
            onNextPageClick={() => setCurrentPage(currentPage + 1)}
            pagesCount={totalPages}
            onChange={(e) => setCurrentPage(e.detail.currentPageIndex)}
          />
        }
      />
    </>
  );
};
