import {
  EntityInformation,
  ExportOfferingsCommand,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { Button, StatusIndicator } from '@amzn/awsui-components-react';
import { ReactElement, useCallback, useState } from 'react';
import { useAuthState } from '../authentication';
import { NoTokenError } from '../errors';
import { useLocation } from 'react-router-dom';

type ExportStatus = 'success' | 'loading' | 'error' | 'idle';
const texts = {
  success: 'Exported',
  loading: 'Loading',
  error: 'Error',
};
export const ExportEntityList = ({
  rows,
}: {
  rows: EntityInformation[];
}): ReactElement => {
  const { token } = useAuthState();
  const [status, setStatus] = useState<ExportStatus>('idle');
  const reset = useCallback(() => setStatus('idle'), []);
  const location = useLocation();

  const getFormattedValue = (entity: EntityInformation) => {
    const entityData =
      'sharedText' in entity && entity.sharedText
        ? entity.sharedText
        : 'service' in entity && entity.service
        ? entity.service
        : null;

    if (!entityData) return '';

    const regionalValues = Object.values(entityData.value);
    return regionalValues
      .map((regionalValue) =>
        regionalValue?.value.map((locString) => locString?.value).join(', ')
      )
      .join('; ');
  };

  const download = useCallback(() => {
    if (!token) throw new NoTokenError();
    setStatus('loading');

    const headers = ['Entity ID', 'Locale', 'Value'];
    const csvData = rows.map((entity) => {
      const entityData =
        'sharedText' in entity && entity.sharedText
          ? entity.sharedText
          : 'service' in entity && entity.service
          ? entity.service
          : null;

      if (entityData) {
        return [
          entityData.entityId,
          entityData.locale,
          getFormattedValue(entity),
        ].join(',');
      }
      return '';
    });

    const csvContent = [headers.join(','), ...csvData].join('\n');

    const a = document.createElement('a');
    a.href = URL.createObjectURL(
      new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    );
    a.setAttribute('target', '_blank');
    const fileName = location.pathname.includes('/shared-text')
      ? 'shared_text_entities'
      : 'service_entities';

    a.download = `${fileName}_${new Date().toISOString().split('.')[0]}.csv`;
    a.click();
    URL.revokeObjectURL(a.href);
    setStatus('success');
    setTimeout(reset, 2000);
  }, [token, rows]);

  return (
    <Button onClick={download} disabled={status !== 'idle'}>
      {status !== 'idle' ? (
        <StatusIndicator type={status}>{texts[status]}</StatusIndicator>
      ) : (
        'Export all'
      )}
    </Button>
  );
};
